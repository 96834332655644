import React from 'react';
import {
	useMutationSerieItemsUpdUser,
	useMutationSerieItemsUpdUserState,
	useMutationSerieUpdUser,
	useMutationSerieUpdUserState,
	useSerie,
	useSerieItems,
	useSerieVariants,
} from './use-series';
import Wrapper from '../../../components/Wrapper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Loader from '../../../components/Loader';
import { ValidationBloc } from '../../../components/ValidationBloc';
import { ValidationState } from '../../../types/global';
import SeriePageFusion from './SeriePageFusion';
import ButtonDialog from '../../../components/Widgets/ButtonDialog';
import { api } from '../../../_services';
import { Box, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import PageTitle from '../../../layout-components/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import SerieGroupList from '../seriesGroup/SerieGroupList';
import { useSerieGroup } from '../seriesGroup/use-series-group';
import SerieUpd from './SerieUpd';

const SeriePage = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const idFormatted = Number(id);
	const { data: serie, isLoading, refetch: refetchSerie } = useSerie(idFormatted);
	const { data: items, refetch: refetchItems } = useSerieItems(idFormatted);
	const { data: variants, refetch: refetchVariantes } = useSerieVariants(idFormatted);
	const { data: groups } = useSerieGroup(serie?.data?.groupId ?? 0);

	const mutationSerieUpdUser = useMutationSerieUpdUser();
	const mutationSerieUpdUserState = useMutationSerieUpdUserState();
	const mutationSerieItemsUpdUser = useMutationSerieItemsUpdUser();
	const mutationSerieItemsUpdUserState = useMutationSerieItemsUpdUserState();

	if (isLoading || !serie?.data) {
		return <Loader />;
	}

	return (
		<>
			<PageTitle titleHeading={serie.data.name} titleDescription='Serie' />

			<Wrapper>
				<ul>
					<li>Statut :&nbsp;{serie.data.status}</li>
					{variants?.data.map((value) => (
						<li key={value.name}>
							{value.userId} : {value.name} :{' '}
							<a href={value.link} target='_blank' rel='noreferrer'>
								{value.link}
							</a>
							- {new Date(value.lastCheck).toLocaleDateString()} - ({value.frequency}
							j)
							{value.userId == 82 && (
								<>
									&nbsp;
									<Button
										variant='outlined'
										size='small'
										disableElevation
										onClick={() => {
											void api
												.call(
													'PUT',
													`/admin/series/${serie.data.id}/check/amazon/`,
													{},
												)
												.then(() => {
													void refetchItems();
												});
										}}>
										Refresh
									</Button>
								</>
							)}
							{value.userId == 73 && (
								<>
									&nbsp;
									<Button
										variant='outlined'
										size='small'
										disableElevation
										onClick={() => {
											void api
												.call(
													'PUT',
													`/admin/series/${serie.data.id}/check/bdphile/`,
													{},
												)
												.then(() => {
													void refetchItems();
												});
										}}>
										Refresh
									</Button>
								</>
							)}
							&nbsp;
							<ButtonDialog
								text='Supprimer'
								color='error'
								onValid={() => {
									void api
										.call(
											'DELETE',
											`/admin/series/${serie.data.id}/variants/${value.id}/`,
											{},
										)
										.then(() => {
											void refetchVariantes();
										});
								}}
								confirmBtnCancel='Annuler'
								confirmBtnValid='Supprimer'
								confirmTitle='Souhaitez vous cette variante ?'
							/>
						</li>
					))}
				</ul>
			</Wrapper>

			<Wrapper>
				<Box sx={{ overflow: 'auto' }}>
					<Table size='small' className='table-responsive'>
						<TableHead>
							<TableRow>
								<TableCell>Lang</TableCell>
								<TableCell>Titre</TableCell>
								<TableCell>Pos</TableCell>
								<TableCell>Type</TableCell>
								<TableCell>User</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items?.data.map((row) => {
								const isNewOne =
									row.user && row.userState != ValidationState.REFUSED;
								const isRefuseOne =
									row.user && row.userState == ValidationState.REFUSED;

								return (
									<TableRow
										key={`${row.item.id}-${row.userId}`}
										className={`${isNewOne ? 'isNewOne' : ''} ${
											isRefuseOne ? 'isRefuseOne' : ''
										}`}>
										<TableCell>{row.item.lang}</TableCell>
										<TableCell>
											<FormControl fullWidth>
												<OutlinedInput
													label='Title'
													onBlur={async (event) => {
														await api
															.call(
																'PUT',
																`/admin/item/${row.item.id}/title/`,
																{
																	action: 'accept',
																	usrId: 0,
																	title: event.target.value,
																},
															)
															.then(() => {
																void refetchItems();
															});
														event.preventDefault();
													}}
													defaultValue={row.item.title}
												/>
											</FormControl>
										</TableCell>
										<TableCell>
											<FormControl fullWidth>
												<OutlinedInput
													label='Pos'
													type={'number'}
													onChange={async (event) => {
														await api
															.call(
																'PUT',
																`/admin/series/${serie.data.id}/items/${row.item.id}/user/${row.userId}/pos/${event.target.value}/`,
																{},
															)
															.then(() => {
																void refetchItems();
															});
														event.preventDefault();
													}}
													defaultValue={row.pos}
												/>
											</FormControl>
										</TableCell>
										<TableCell>
											<select
												onChange={async (event) => {
													await api
														.call(
															'PUT',
															`/admin/series/${serie.data.id}/items/${row.item.id}/user/${row.userId}/type/${event.target.value}/`,
															{},
														)
														.then(() => {
															void refetchItems();
														});
													event.preventDefault();
												}}
												value={row.type}>
												<option>STANDARD</option>
												<option>HORSSERIE</option>
												<option>INTEGRAL</option>
											</select>
										</TableCell>
										<TableCell>{row.user?.name}</TableCell>
										<TableCell>
											{row.user && (
												<ValidationBloc
													onValidate={() => {
														mutationSerieItemsUpdUser.mutate(
															{
																serieId: serie.data.id,
																itemId: row.item.id,
																userId: row.userId,
																newUserId: 0,
															},
															{
																onSuccess: () => {
																	void refetchItems();
																},
															},
														);
													}}
													onRefuse={() => {
														mutationSerieItemsUpdUserState.mutate(
															{
																serieId: serie.data.id,
																itemId: row.item.id,
																userId: row.userId,
																state: ValidationState.REFUSED,
															},
															{
																onSuccess: () => {
																	void refetchItems();
																},
															},
														);
													}}
													state={row.userState}
												/>
											)}
											<br />
											<Button
												variant='outlined'
												size='small'
												disableElevation
												onClick={() =>
													navigate(`/items/detail/${row.item.id}`)
												}>
												Page détail
											</Button>
											&nbsp;
											<ButtonDialog
												text='Supprimer'
												color='error'
												onValid={() =>
													void api
														.call(
															'DELETE',
															`/admin/series/${serie.data.id}/items/${row.item.id}/user/${row.userId}/`,
															{},
														)
														.then(() => {
															void refetchItems();
														})
												}
												confirmBtnCancel='Annuler'
												confirmBtnValid='Supprimer'
												confirmTitle='Souhaitez vous supprimer cette item de la série ?'
												confirmDesc={row.item.title}
											/>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</Box>
			</Wrapper>

			{groups && (
				<Wrapper sectionHeading='Groupement'>
					<SerieGroupList serieGroup={groups.data} />
				</Wrapper>
			)}

			<SeriePageFusion serie={serie.data} />

			{serie.data.userId > 0 && (
				<Wrapper sectionHeading='Validation'>
					<ValidationBloc
						onValidate={() => {
							mutationSerieUpdUser.mutate(
								{
									serieId: serie.data.id,
									userId: 0,
								},
								{
									onSuccess: () => {
										void refetchSerie();
									},
								},
							);
						}}
						onRefuse={() => {
							mutationSerieUpdUserState.mutate(
								{
									serieId: serie.data.id,
									state: ValidationState.REFUSED,
								},
								{
									onSuccess: () => {
										void refetchSerie();
									},
								},
							);
						}}
						state={serie.data.userState}
					/>
				</Wrapper>
			)}

			<SerieUpd serie={serie.data} onUpd={refetchSerie} />
		</>
	);
};

export default SeriePage;
